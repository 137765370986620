.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

div.error-message-box {
    text-align: center;
    line-height:1.5em;
    min-height:3em;
}

div.no-login-error {
    display: none;
}

div.login-error {
    background-color: #f8d7da;
    border-color: #f5c6cb;
    color: #721c24;
}

div.graduation-background {
    /*margin-top: -20px;*/
    min-height: 100vh;
    background-image: url('/public/placeholder.jpg');
    background-size: cover;
    box-shadow: inset 0 0 0 1000px rgba(0,0,0,.8);
}

div.graduation-inprogress {
    color: white;
    position: absolute;
    top: 50%;
    text-align: center;
    margin-top: -50px;
    width: 100%;
    height: 100px;
}
div.graduation-inprogress h1 {
    font-size: 2em;
    font-weight: bold;
}

div.graduation-inprogress div {
    margin-left: 50px;
    margin-right: 50px;
}